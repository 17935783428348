interface String {
    format(...replacements: any[]): string;
    startsWith(str: string): boolean;
    toArray(): string[];
    contains(v: string): boolean;

    /**
     * Same as string.replace, but won't stop at the first occurence. Because, yes, the standard javascript string.replace only replaces the first occurence.
     */
    replaceAll(search: string, replacement: string): string;
}

String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
};

String.prototype.contains = function (v: string): boolean {
    return this.indexOf(v) > -1;
}

String.prototype.replaceAll = function (search: string, replacement: string): string {
    return this.replace(new RegExp(search, 'g'), replacement);
}

String.prototype.startsWith = function (str: string): boolean {
    return this.indexOf(str) == 0;
};
